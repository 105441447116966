@use '@material/textfield';
@use '@material/checkbox';
@use '@material/ripple';
@use '@material/tab';
@use '@material/tab-indicator';
@use 'styles/mixins' as mixins;
@use 'styles/include-media' as media;
@use 'styles/truncate' as truncate;

body {
  // mat-accordion start
  .mat-expansion-panel {
    border-top: 1px solid var(--boels-color-gray-400);
    border-bottom: 1px solid var(--boels-color-gray-400);
    margin: -1px 0 !important;
    border-radius: 0 !important;
    background: var(--boels-color-gray-200);

    &:not([class*='mat-mdc-elevation-z']) {
      box-shadow: none;
    }

    .mat-expansion-panel-header {
      @include mixins.side-paddings();
      height: auto;
      padding-top: 17px;
      padding-bottom: 17px;
      background-color: var(--boels-color-white);

      &.mat-expanded {
        background: var(--boels-color-gray-200);

        .mat-expansion-panel-header-title {
          color: var(--boels-color-primary);

          .error-label {
            display: none;
          }
        }
      }

      .mat-expansion-panel-header-title {
        font-size: var(--boels-font-size-lg);
        line-height: 1.2;
        color: var(--boels-color-gray-800);

        .error-label {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          overflow: hidden;
          background-color: var(--boels-color-danger);
          margin: 0 0 6px 6px;
        }
      }

      .mat-expansion-indicator {
        margin-right: 10px;
      }
    }

    .mat-expansion-panel-body {
      @include mixins.side-paddings();
      padding-bottom: 0;

      @include media.breakpoint('>=md') {
        background: var(--boels-color-gray-200);
      }
    }

    .mat-expansion-panel-content {
      line-height: 1.25rem;
    }

    &.no-spaces {
      .mat-expansion-panel-body {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  // mat-mdc-accordion finish

  // mat-mdc-tab start
  .mat-mdc-tab-header.mat-mdc-tab-header.mat-mdc-tab-header.mat-mdc-tab-header {
    @include tab-indicator.underline-height(4px);
    @include ripple.states(green);

    // Makes the tab line animate even though we disabled the animation for the tab contents
    --mat-tab-animation-duration: 250ms;

    background: var(--boels-color-white);
    border-bottom: 1px solid var(--boels-color-gray-300);
  }

  .mat-mdc-tab-group,
  .mat-mdc-tab-nav-bar {
    --mat-tab-header-disabled-ripple-color: var(--boels-color-gray-300);
    --mat-tab-header-active-ripple-color: var(--boels-color-gray-700);
    --mat-tab-header-inactive-ripple-color: var(--boels-color-gray-400);
  }

  .mat-mdc-tab-group > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0 !important;
    font-size: var(--boels-font-size-md);
    line-height: 1.2;
    letter-spacing: 0;
    color: var(--boels-color-gray-800);
    opacity: 1;
    font-weight: 400;
    padding: 0;
    min-width: unset;
    -webkit-font-smoothing: auto;

    &-active {
      color: var(--boels-color-primary);
    }
  }

  .mat-mdc-ink-bar {
    height: 4px;
    background: var(--boels-color-primary);
  }

  .mat-mdc-tab-labels {
    grid-gap: var(--boels-space-20);
  }

  // mat-mdc-tab finish

  // mat-mdc-form-field start

  .mat-mdc-hint {
    font-size: 11px;
    color: var(--boels-color-gray-600);
    -webkit-text-size-adjust: none;
    @include truncate.truncate(100%);

    @include media.breakpoint('>=md') {
      font-size: 13px;
    }
  }

  .mat-mdc-form-field {
    // Included in order to override without using !important
    // Repeated occurrences of the same simple selector are allowed and do increase specificity.
    &.mat-mdc-form-field.mat-mdc-form-field {
      width: 100%;
      -webkit-font-smoothing: auto;

      .right-buttons-mat-suffix-wrap {
        display: flex;
        align-items: center;
      }

      .mdc-text-field {
        @include textfield.ink-color($black);
        @include textfield.fill-color($white);
        @include textfield.floating-label-float-transition(400ms);
        @include textfield.placeholder-color(var(--boels-color-gray-400));

        border-width: 1px;
        border-style: solid;
        border-color: var(--bds-component-input-text-border-color);
        border-radius: 10px; // (only does top borders on filled: @include textfield.shape-radius(10px))

        .mdc-line-ripple::before {
          content: none;
        }

        .mdc-line-ripple::after {
          border-width: 1px;
        }

        .mat-mdc-form-field-focus-overlay {
          background-color: $white;
        }

        .mat-mdc-form-field-infix {
          width: 100%; // fixes 180px width default
        }

        .mdc-floating-label {
          font-size: 0.9375rem;

          &--float-above {
            color: var(--boels-color-gray-800);
            font-weight: bold;
            font-size: 0.9375rem;
            transform: translateY(-135%);
          }
        }

        &--focused {
          @include textfield.caret-color($black);
        }

        &--invalid {
          @include textfield.caret-color(var(--boels-color-error));
          border-color: var(--boels-color-error);

          .mdc-floating-label--float-above {
            color: var(--boels-color-error);
          }
        }

        &--disabled {
          @include textfield.disabled-ink-color(var(--boels-color-gray-800));
          @include textfield.disabled-fill-color(var(--boels-color-gray-200));
          @include textfield.disabled-placeholder-color(var(--boels-color-gray-400));
          border-color: var(--boels-color-gray-300);

          .mdc-floating-label--float-above {
            color: var(--boels-color-gray-600);
          }
        }

        .right-buttons-mat-suffix-wrap {
          padding-right: 16px;
          padding-left: 8px;
        }
      }
    }
  }

  .mat-mdc-form-field-type-mat-mdc-select {
    .mat-mdc-form-field-flex {
      padding: 0 15px 0 17px !important;
    }
  }

  .mat-mdc-error {
    font-size: 11px;

    @include media.breakpoint('>=md') {
      font-size: 13px;
    }
  }

  .mat-mdc-select-arrow {
    display: none;
  }

  .mat-mdc-mini-fab.mat-accent {
    background-color: var(--boels-color-gray-800);
  }

  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    box-shadow: none;
  }
  // mat-mdc-form-field finish

  // mat datepicker start

  .mat-datepicker-toggle-active {
    color: var(--boels-color-primary);
  }

  .mat-datepicker-toggle-default-icon {
    color: var(--boels-color-gray-800);
  }

  // mat datepicker finish

  // mat-mdc-checkbox start

  .mat-mdc-checkbox {
    &.mat-mdc-checkbox.mat-mdc-checkbox {
      @include checkbox.ink-color($white);
      @include checkbox.container-colors(
        var(--boels-color-gray-400),
        transparent,
        var(--boels-color-primary),
        var(--boels-color-primary)
      );
      @include checkbox.disabled-ink-color($white);
      @include checkbox.disabled-container-colors(
        var(--boels-color-gray-300),
        var(--boels-color-gray-100),
        var(--boels-color-gray-300),
        var(--boels-color-gray-300)
      );

      position: relative;

      .mdc-form-field {
        align-items: flex-start;
        -webkit-font-smoothing: auto;
      }

      .mdc-checkbox {
        margin-left: -11px;

        .mdc-checkbox__background {
          border-width: 1px;
          border-radius: 0;

          .mdc-checkbox__checkmark {
            color: var(--boels-color-white);
          }

          .mdc-checkbox__mixedmark {
            border-color: var(--boels-color-white);
          }
        }
      }

      .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
        opacity: 0.05;
      }

      .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
      .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background {
        border-width: 2px;
      }

      .mdc-label {
        margin-top: 7px;
        line-height: 24px;
        font-size: var(--boels-font-size-sm);
        color: var(--boels-color-gray-800);

        @include media.breakpoint('>=md') {
          font-size: var(--boels-font-size-base);
        }
      }
    }
  }

  // mat-mdc-checkbox finish

  // mat-dialog start
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      padding: 24px;
      border-radius: 4px;
    }

    .mat-mdc-dialog-title {
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  // mat-dialog finish

  button.mdc-button {
    .mdc-button__label {
      display: flex;
      align-items: center;
    }
  }

  // mat-icon finish
}
