@use 'sass:map';
@use '@angular/material' as mat;

@use './palette';

@include mat.core();

// COLORS
$boels-primary: mat.m2-define-palette(palette.$boels-primary-color, 500, 100, 700);
$boels-accent: mat.m2-define-palette(palette.$boels-accent-color, 500, 100, 700);
$boels-warn: mat.m2-define-palette(palette.$boels-warn-color, 200, 200, 200);

// TYPOGRAPHY
$boels-typography: mat.m2-define-typography-config(
  $font-family: 'Barlow',
  $body-1:
    mat.m2-define-typography-level(
      $font-size: 16px,
      $font-weight: 400,
      $letter-spacing: -0.1px,
    ),
  $body-2:
    mat.m2-define-typography-level(
      $font-size: 14px,
      $font-weight: 400,
      $letter-spacing: -0.1px,
    ),
);

// THEME CONFIG
$boels-theme-config: (
  color: (
    primary: $boels-primary,
    accent: $boels-accent,
    warn: $boels-warn,
  ),
  typography: $boels-typography,
  density: 0,
);

$theme: mat.m2-define-light-theme($boels-theme-config);

$color: map.merge(
  map.get($theme, color),
  (
    background: palette.$boels-background-palette,
    foreground: palette.$boels-foreground-palette,
  )
);

$boels-theme: map.merge(
  $theme,
  (
    color: $color,
  )
);

@include mat.all-component-themes($boels-theme);

// MATERIAL OVERRIDES
@mixin boels-material-overrides($theme) {
  @include mat.icon-button-density(-1);
}

/**
 * Style dictionary auto generated
 */
@import 'boels-tokens';
@import 'cramo-tokens';

@mixin boels-color-scheme-dark {
  @include boels-reference-tokens();
  --bds-brand-color-cta: var(--bds-brand-color-cta-tertiary);
  --bds-core-color-white: var(--bds-core-color-darkwhite);

  --bds-component-button-color-cta-text: var(--bds-core-color-black);
  --bds-component-button-color-cta-text-hover: var(--bds-core-color-black);
  --bds-component-button-color-cta-container-hover: var(--bds-core-color-white);
  --bds-component-button-color-default-text-hover: var(--bds-core-color-black);
  --bds-component-button-color-default-container-hover: var(--bds-core-color-white);
  --bds-component-button-color-default-border: var(--bds-core-color-white);
  --bds-component-button-color-default-border-hover: var(--bds-core-color-white);
  --bds-component-button-color-disabled-text: var(--bds-brand-color-lightblack);
  --bds-component-button-color-disabled-container: var(--bds-brand-color-darkgray);
  --bds-component-button-color-promote-text: var(--bds-core-color-black);
  --bds-component-button-color-promote-container: var(--bds-core-color-white);
  --bds-component-button-color-promote-text-hover: var(--bds-core-color-black);
  --bds-component-button-color-promote-container-hover: var(--bds-brand-color-cta);
  --bds-component-drawer-border-color: var(--bds-brand-color-darkgray);
  --bds-component-input-checkbox-disabled-text: var(--bds-brand-color-lightblack);
  --bds-component-input-checkbox-disabled-container: var(--bds-brand-color-darkgray);

  --bds-semantic-color-input-disabled-text: var(--bds-brand-color-darkgray);
  --bds-semantic-color-default-text: var(--bds-core-color-white);
  --bds-semantic-color-default-container: var(--bds-brand-color-lightblack);
  --bds-semantic-color-inner-border: var(--bds-brand-color-darkgray);
  --bds-semantic-color-background-hover: var(--bds-brand-color-darkgray);
  --bds-semantic-focus-outline: 2px solid var(--bds-brand-color-lightblack);
  --bds-semantic-focus-box-shadow: 0 0 0 2px var(--bds-core-color-darkwhite);
  --bds-semantic-focus-inversed-outline: 2px solid var(--bds-core-color-darkwhite);
  --bds-semantic-focus-inversed-box-shadow: 0 0 0 2px var(--bds-brand-color-lightblack);

  .bds-input-checkbox__input:disabled:checked::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%22222200' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3e%3c/svg%3e");
  }

  .bds-checkbox--indeterminate .bds-input-checkbox__input:disabled::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%22222200' d='M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z'/%3e%3c/svg%3e");
  }
}

@mixin cramo-color-scheme-dark {
  @include boels-reference-tokens();
  --bds-semantic-color-input-disabled-text: var(--bds-brand-color-darkgray);
  --bds-semantic-color-default-text: var(--bds-core-color-white);
  --bds-semantic-color-default-container: var(--bds-brand-color-black);
  --bds-semantic-color-inner-border: var(--bds-brand-color-darkgray);
  --bds-semantic-color-background-hover: var(--bds-brand-color-darkgray);
}

:root {
  color-scheme: light dark;
  @include boels-tokens();
  @include boels-reference-tokens();
}

.boels {
  @include boels-tokens();
  @include boels-reference-tokens();

  .color-scheme-dark,
  .cdk-drag-preview {
    @include boels-color-scheme-dark;
  }
}

.cramo {
  @include cramo-tokens();
  @include cramo-reference-tokens();

  .color-scheme-dark,
  .cdk-drag-preview {
    @include cramo-color-scheme-dark;
  }
}
