.boels-tooltip {
  background-color: var(--boels-color-gray-800);
  color: var(--boels-color-white);
  padding: var(--boels-space-8) var(--boels-space-12);
  border-radius: var(--boels-space-4);
  font-size: 16px;
  max-width: 200px;
  text-align: center;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  word-break: break-word;

  &-wrapper {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
}

// Triangle for tooltip on top
.boels-tooltip.top::before {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: var(--boels-space-8) var(--boels-space-8) 0 var(--boels-space-8);
  border-color: var(--boels-color-gray-800) transparent transparent transparent;
}

// Triangle for tooltip on bottom
.boels-tooltip.bottom::before {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 var(--boels-space-8) var(--boels-space-8) var(--boels-space-8);
  border-color: transparent transparent var(--boels-color-gray-800) transparent;
}

// Triangle for tooltip on left
.boels-tooltip.left::before {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-width: var(--boels-space-8) 0 var(--boels-space-8) var(--boels-space-8);
  border-color: transparent transparent transparent var(--boels-color-gray-800);
}

// Triangle for tooltip on right
.boels-tooltip.right::before {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-width: var(--boels-space-8) var(--boels-space-8) var(--boels-space-8) 0;
  border-color: transparent var(--boels-color-gray-800) transparent transparent;
}
