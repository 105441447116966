/**
    The contrast sets the font color over those background colors.
    It's very hard (or even impossible) to calculate via CSS where the font should be bright (white) or dark (black with 0.87 opacity)
    so it is easily readable even to color blind people.
    So this is set manually and hard-coded into the palette definition
*/

// @deprecated renamed to $dark-primary-text.
// @breaking-change 8.0.0
$black-87-opacity: rgba(black, 0.87);
// @deprecated renamed to $light-primary-text.
// @breaking-change 8.0.0
$white-87-opacity: rgba(white, 0.87);
// @deprecated use $dark-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$black-12-opacity: rgba(black, 0.12);
// @deprecated use $light-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$white-12-opacity: rgba(white, 0.12);
// @deprecated use $dark-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$black-6-opacity: rgba(black, 0.06);
// @deprecated use $light-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$white-6-opacity: rgba(white, 0.06);

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.39);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$boels-primary-color: (
  type: primary,
  50: #ffefe4,
  75: #ffe6d6,
  100: #ffdecb,
  125: #ffd8c0,
  150: #ffd3b8,
  200: #ffcead,
  225: #ffc5a1,
  250: #ffbf98,
  300: #ffaf7f,
  350: #ff9f65,
  400: #ff904c,
  425: #ff8034,
  450: #ff761b,
  500: #ff6600,
  600: #e75d00,
  700: #de5201,
  800: #b44800,
  900: #9b3e00,
  925: #813400,
  950: #672900,
  contrast: (
    50: $black-87-opacity,
    75: $black-87-opacity,
    100: $black-87-opacity,
    150: $black-87-opacity,
    200: $black-87-opacity,
    225: $black-87-opacity,
    250: $black-87-opacity,
    300: $black-87-opacity,
    350: $black-87-opacity,
    400: $black-87-opacity,
    425: $black-87-opacity,
    450: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    925: $white-87-opacity,
    950: $white-87-opacity,
  ),
);
$boels-accent-color: (
  type: accent,
  25: #f8f9fa,
  50: #f5f6f8,
  100: #f2f4f6,
  150: #eff1f4,
  175: #ebedf1,
  200: #e6e9ee,
  225: #e2e5eb,
  250: #dfe3e9,
  275: #d6dbe3,
  300: #d0d6e0,
  325: #c7ced9,
  340: #c1c9d5,
  350: #b2bccb,
  400: #a8b4c4,
  425: #99a7ba,
  450: #93a2b6,
  500: #8495ac,
  550: #7588a2,
  600: #667b97,
  650: #5b6d88,
  700: #516179,
  750: #475569,
  800: #3d495a,
  850: #333d4b,
  900: #28303c,
  950: #1e242c,
  960: #1b2027,
  970: #181c23,
  980: #14181d,
  990: #090b0e,
  contrast: (
    25: $black-87-opacity,
    50: $black-87-opacity,
    100: $black-87-opacity,
    150: $black-87-opacity,
    200: $black-87-opacity,
    225: $black-87-opacity,
    250: $black-87-opacity,
    275: $black-87-opacity,
    300: $black-87-opacity,
    325: $black-87-opacity,
    340: $black-87-opacity,
    350: $black-87-opacity,
    400: $black-87-opacity,
    425: $black-87-opacity,
    450: $black-87-opacity,
    500: white,
    550: white,
    600: white,
    650: white,
    700: white,
    750: white,
    800: $white-87-opacity,
    850: $white-87-opacity,
    900: $white-87-opacity,
    950: $white-87-opacity,
    960: $white-87-opacity,
    970: $white-87-opacity,
    980: $white-87-opacity,
    990: $white-87-opacity,
  ),
);
$boels-warn-color: (
  type: warn,
  10: #ff6e6e,
  25: #ff3636,
  50: #ed0000,
  100: #cf0000,
  200: #b00000,
  210: #910000,
  225: #fd8503,
  250: #ff7809,
  300: #ef6c00,
  400: #de5201,
  420: #8ff600,
  425: #80dc00,
  450: #71c200,
  475: #62a900,
  480: #599a00,
  500: #538f00,
  600: #447600,
  625: #406e00,
  650: #365d00,
  660: #2d4e00,
  675: #274400,
  contrast: (
    25: $black-87-opacity,
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: white,
    225: $black-87-opacity,
    250: $black-87-opacity,
    300: $black-87-opacity,
    400: white,
    425: $black-87-opacity,
    450: $black-87-opacity,
    475: $black-87-opacity,
    500: white,
    600: white,
    650: $white-87-opacity,
    675: $white-87-opacity,
  ),
);

$boels-background-palette: (
  dialog: white,
  status-bar: lightgrey,
  app-bar: white,
  background: #f2f4f6,
  hover: #f5f6f8,
  card: white,
  disabled-list-option: lightgrey,
  tooltip: #333d4b,
);

$boels-foreground-palette: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);
