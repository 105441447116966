/**
 * Default focus style that should be used in all components that define :focus or :focus-visible
 * renders the focus around the border with an extra contrast ring around that (typically that ring is not visible as it
 * should have the same color as the most common background color)
 * $inset: when true the focus ring is drawn at the inside of the edge of the element
 */
@mixin focus($inset: false) {
  border-radius: var(--bds-semantic-focus-border-radius);
  outline: var(--bds-semantic-focus-outline);
  box-shadow: var(--bds-semantic-focus-box-shadow);

  @if $inset == true {
    // draws the outer outline on the inside instead of outside of the contrast ring
    // This means we need to toggle colors so the scheme out the outlines remains consistent with $inset: false
    outline: var(--bds-semantic-focus-inversed-outline);
    box-shadow: var(--bds-semantic-focus-inversed-box-shadow);
    outline-offset: var(--bds-semantic-focus-outline-offset-inset);
  } @else {
    outline-offset: var(--bds-semantic-focus-outline-offset-outset);
  }
}

@mixin focus-inversed($inset: false) {
  border-radius: var(--bds-semantic-focus-border-radius);
  outline: var(--bds-semantic-focus-inversed-outline);
  box-shadow: var(--bds-semantic-focus-inversed-box-shadow);

  @if $inset == true {
    // draws the outer outline on the inside instead of outside of the contrast ring
    // This means we need to toggle colors so the scheme out the outlines remains consistent with $inset: false
    outline: var(--bds-semantic-focus-outline);
    box-shadow: var(--bds-semantic-focus-box-shadow);
    outline-offset: var(--bds-semantic-focus-outline-offset-inset);
  } @else {
    outline-offset: var(--bds-semantic-focus-outline-offset-outset);
  }
}

a[role='link'] {
  cursor: pointer;
}
