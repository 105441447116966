ol,
ul {
  list-style: none;

  li {
    list-style-type: none;
  }
}

figure,
ul {
  all: unset;
}
