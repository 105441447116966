@mixin content-grid {
  --padding-inline: 1rem;
  --content-max-width: 1090px;
  --breakout-max-width: 1600px;

  --breakout-size: calc((var(--breakout-max-width) - var(--content-max-width)) / 2);

  display: grid !important;
  grid-template-columns:
    [full-width-start]
    minmax(var(--padding-inline), 1fr)
    [breakout-start]
    minmax(0, var(--breakout-size))
    [content-start]
    min(100% - (var(--padding-inline) * 2), var(--content-max-width))
    [content-end]
    minmax(0, var(--breakout-size))
    [breakout-end]
    minmax(var(--padding-inline), 1fr)
    [full-width-end];

  > * {
    grid-column: content;
  }

  > .breakout,
  &.grid-column-breakout > * {
    grid-column: breakout;
  }

  > .full-width,
  &.grid-column-full-width > * {
    grid-column: full-width;
  }

  // This allows to show breakout area's nested elements at content width.
  // Nested content like this will not be able to access full width anymore.
  // Use sparingly only, with care and caution to avoid layout issues.
  > .breakout .content-grid {
    --padding-inline: 0rem;
  }
}

.content-grid {
  @include content-grid;
}
