@forward 'styles/themes/boels-theme.scss' show $boels-theme, $boels-typography, boels-material-overrides;

@use '@angular/material' as mat;
@use '@material/dialog';
@use 'styles/themes/boels-theme' as boels-theme;

$breakpoints: (
  'xxl': 1400px,
  'xl': 1200px,
  'lg': 992px,
  'md': 768px,
  'sm': 576px,
  'xs': 0px,
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)',
);

@use 'styles/include-media' as media;
@use 'styles/truncate' as truncate;
@forward 'styles/reset';
@forward 'styles/accessibility';
@forward 'styles/fonts';
@forward 'styles/typography';
@forward 'styles/spacers';
@forward 'styles/mixins';
@forward 'styles/boels-tooltip';
@import 'styles/colors';
@import 'styles/chat';
@import 'styles/mat-overrides';
@import 'styles/buttons';
@import 'styles/layout';
@import 'styles/messages';

@mixin boels-styles($theme) {
  @include boels-theme.boels-material-overrides($theme);
}

@include boels-styles(boels-theme.$boels-theme);

:root {
  --navigation-height-xs: 52px;
  --modal-selector-width: 860px;
  --modal-selector-width-md: 630px;
  --modal-selector-width-sm: 510px;
  --modal-selector-width-xs: 100%;
}

html,
body {
  height: 100%;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-display: optional;
  line-height: var(--bds-semantic-typography-size-root-line-height);
  box-sizing: border-box;
}

small {
  font-size: var(--bds-semantic-typography-size-small);
  line-height: var(--bds-semantic-typography-size-small-line-height);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

button {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: var(--bds-semantic-typography-size-root-line-height);
}

a:not(.btn) {
  color: var(--bds-semantic-color-default-text);
  text-decoration: underline;
  transition: all 0.2s;

  &:hover {
    color: var(--boels-color-primary);
  }

  &:active {
    color: var(--boels-color-primary-dark);
  }

  &.no-styles {
    color: inherit;
    text-decoration: none;
    transition: none;

    &:hover {
      color: inherit;
    }
  }

  &.parent-styles {
    color: inherit;

    &:hover {
      color: var(--boels-color-primary);
    }

    &:active {
      color: var(--boels-color-primary-dark);
    }
  }
}

body {
  margin: 0;
  background: none;

  @include media.breakpoint('>=md') {
    background: var(--boels-color-gray-200);
  }

  &.page-background-white {
    background: var(--boels-color-white);
  }
}

[hidden] {
  display: none !important;
}

.mat-spinner circle,
.mat-progress-spinner circle {
  stroke: var(--boels-color-primary);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .margin-top-for-ie11 {
    overflow: hidden;
    margin-top: 16px;
    margin-bottom: 0px;
  }
}

.cdk-describedby-message-container.cdk-visually-hidden {
  display: none;
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(34, 34, 34, 0.7);
}

.modal-language-selector,
.modal-debtor-selector,
.modal-preferences-selector {
  height: auto;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100% !important;
  bottom: var(--navigation-height-xs);

  @include media.breakpoint('>=md') {
    position: static !important;
  }

  .mat-mdc-dialog-surface {
    position: relative;
    padding: 0 !important;

    @include media.breakpoint('<md') {
      box-shadow: none;
    }

    @include media.breakpoint('>=md') {
      border-radius: 10px;
    }
  }
}

.modal-language-selector {
  .mat-mdc-dialog-content {
    padding: 0;
    margin: 0;

    @include media.breakpoint('>=md') {
      min-height: 70vh;
      max-height: calc(100vh - 40px);
      padding: 16px 24px;
    }
  }
}

.modal-debtor-selector,
.modal-preferences-selector {
  .mat-mdc-dialog-content {
    padding: 0;
    margin: 0;

    @include media.breakpoint('>=md') {
      padding: 16px 24px;
    }

    @include media.breakpoint('>=md', 'portrait') {
      min-height: 70vh;
      max-height: 80vh;
    }

    @include media.breakpoint('>=lg') {
      min-height: 70vh;
      max-height: 80vh;
    }
  }
}

.modal-preferences-selector {
  .mat-mdc-dialog-content {
    @include media.breakpoint('>=md', 'portrait') {
      min-height: auto;
    }

    @include media.breakpoint('>=lg') {
      min-height: auto;
    }
  }
}

.cdk-overlay-pane {
  .mat-mdc-menu-panel {
    min-height: 0;

    .mat-mdc-menu-content:not(:empty) {
      padding: 0;
    }
  }

  .cdk-keyboard-focused .mat-calendar-body-active,
  .cdk-program-focused .mat-calendar-body-active {
    & > .mat-calendar-body-cell-content {
      &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: rgba(255, 102, 0, 0.36);
      }
    }
  }
}

// datepicker start

.mat-datepicker-content {
  box-shadow: none;
  border: 1px solid var(--boels-color-gray-300);
  border-radius: 10px !important;
  margin-top: 10px;

  .mat-calendar {
    width: 266px !important;
    height: 308px !important;
  }

  .mat-calendar-next-button,
  .mat-calendar-previous-button {
    display: inline-block !important;
  }

  .mat-calendar-controls {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .mat-calendar-spacer {
    display: inline-block;
  }

  .mat-calendar-header {
    padding: 12px 0;
    text-align: center;
  }

  .mat-calendar-body-selected {
    background-color: var(--boels-color-primary);
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    // border-color: transparent !important;
    --mat-datepicker-calendar-date-today-outline-color: transparent;
    background-color: rgba(255, 102, 0, 0.5);
    color: var(--boels-color-white);
  }

  .mat-calendar-previous-button::after,
  .mat-calendar-next-button::after {
    margin: 16.2px;
  }

  button.mat-calendar-previous-button.mat-mdc-icon-button {
    margin-left: 5px;
    float: left;
  }

  button.mat-calendar-next-button.mat-mdc-icon-button {
    float: right;
    margin-right: 5px;
  }

  .mat-calendar-table-header-divider::after {
    margin: 0 8px;
  }

  .mat-calendar-body-cell-content {
    font-size: var(--boels-font-size-sm);
    border-radius: 5px;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--boels-color-primary);
    color: var(--boels-color-white);
  }

  .mat-calendar-table-header tr:first-child th {
    font-size: 12px;
    font-weight: bold;
    line-height: 13px;
    color: var(--bds-semantic-color-default-text);
    padding: 7px 0 4px 0 !important;
  }

  .mat-calendar-table-header tr:nth-child(2) th {
    padding: 0 0 6px 0 !important;
  }

  .mat-calendar-content {
    padding: 0 10px 6px !important;
  }

  .mat-calendar-body-cell {
    padding: 17px 19px !important;

    &.mat-calendar-body-active {
      .mat-calendar-body-selected {
        background-color: var(--boels-color-primary);
      }
    }
  }
}

// datepicker end

.component-full-width {
  background: var(--boels-color-white);
  padding-left: var(--boels-space-8);
  padding-right: var(--boels-space-8);
  display: block;
  margin: auto;

  &--no-background {
    background: none;
  }

  &--forced-paddings {
    padding: var(--boels-space-8);
  }

  &--paddings {
    padding: 0;
  }

  &--top-border {
    @include media.breakpoint('>=md') {
      border-top: 1px solid var(--boels-color-gray-300);
    }
  }

  @include media.breakpoint('>=sm') {
    padding-left: var(--boels-space-16);
    padding-right: var(--boels-space-16);

    &--paddings {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media.breakpoint('>=md') {
    padding-left: var(--boels-space-16);
    padding-right: var(--boels-space-16);

    &--paddings {
      padding-top: var(--boels-space-16);
      padding-bottom: var(--boels-space-16);
    }
  }

  @include media.breakpoint('>=lg') {
    padding-left: var(--boels-space-48);
    padding-right: var(--boels-space-48);
  }

  @include media.breakpoint('>=xl') {
    padding-left: var(--boels-space-84);
    padding-right: var(--boels-space-84);
  }
}

.cdk-overlay-pane {
  // Helper classes for modals
  &.helper-modal {
    &-no-paddings {
      .mat-mdc-dialog-surface {
        padding: 0;
      }
    }

    &-full-width {
      max-width: 100% !important;
    }

    &-mobile-full-width-height {
      @include media.breakpoint('<=md') {
        width: 100%;
        max-width: 100% !important;
        height: calc(100% - var(--navigation-height-xs));
        margin-bottom: auto;
      }
    }

    &-scrollable {
      overflow: auto;
    }
  }

  &.full-width-height {
    width: 100%;
    max-width: 100% !important;
    height: 100%;

    .mdc-dialog__container {
      width: 100%;
    }

    @include media.breakpoint('>=md') {
      width: 100%;
      max-width: 80vw !important;
      height: auto;
    }

    .mat-mdc-dialog-container {
      @include media.breakpoint('<md') {
        box-shadow: none;
      }
    }
  }

  .mat-mdc-dialog-container {
    @include dialog.content-ink-color($gray-800, 0.87);

    position: relative;

    .mat-mdc-dialog-surface {
      border-radius: 0;

      @include media.breakpoint('>=md') {
        border-radius: 10px;
      }
    }

    .mat-mdc-dialog-content {
      letter-spacing: inherit;
    }

    .mdc-dialog__title + .mdc-dialog__content,
    .mdc-dialog__header + .mdc-dialog__content {
      padding-top: 12px;
    }
  }
}

.helper-cursor-pointer {
  cursor: pointer;
}

.control--checkbox {
  display: flex;
  flex-direction: column;

  .checkbox-label-inner {
    @include truncate.truncate(100%);
  }
}
