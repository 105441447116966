@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-regular.woff2') format('woff2'),
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src:
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-italic.woff2') format('woff2'),
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src:
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-500.woff2') format('woff2'),
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-500.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src:
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-500italic.woff2') format('woff2'),
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-500italic.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src:
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-600.woff2') format('woff2'),
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src:
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-600italic.woff2') format('woff2'),
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-600italic.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src:
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-700.woff2') format('woff2'),
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src:
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-700italic.woff2') format('woff2'),
    url('../assets/fonts/barlow/barlow-v5-latin-ext_latin-700italic.woff') format('woff');
}
