@use 'styles/include-media' as media;
@import 'variables';

// Type
$font-family-sans-serif:
  'Barlow',
  -apple-system,
  blinkmacsystemfont,
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  arial,
  sans-serif;
$headings-font-family:
  'Barlow',
  -apple-system,
  blinkmacsystemfont,
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  arial,
  sans-serif;
$font-family-base: $font-family-sans-serif;

/* -------------------------------------------------------------------------- */
/*                       font-size variants declarations                      */
/* -------------------------------------------------------------------------- */

$font-size-base: 1rem; // 16px
$font-size-hero-large: $font-size-base * 3.25; // 52px
$font-size-hero: $font-size-base * 2.5; // 40px
$font-size-xxxxl: $font-size-base * 2; // 32px
$font-size-xxxl: $font-size-base * 1.75; // 28px
$font-size-xxl: $font-size-base * 1.5; // 24px
$font-size-xl: $font-size-base * 1.25; // 20px
$font-size-lg: $font-size-base * 1.125; // 18px
$font-size-md: $font-size-base * 1; // 16px
$font-size-sm: $font-size-base * 0.875; // 14px
$font-size-xs: $font-size-base * 0.75; // 12px
$font-size-xxs: $font-size-base * 0.625; // 10px (9px @dotcom)

$font-weights: (
  light: 100,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
);

$font-sizes: (
  base: #{$font-size-base},
  //hero-large: #{$font-size-hero-large},
  //hero: #{$font-size-hero},
  //xxxxl: #{$font-size-xxxxl},
  //xxxl: #{$font-size-xxxl},
  xxl: #{$font-size-xxl},
  xl: #{$font-size-xl},
  lg: #{$font-size-lg},
  //md: #{$font-size-md},
  sm: #{$font-size-sm},
  xs: #{$font-size-xs},
  xxs: #{$font-size-xxs},
);

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1; // 16px

$h1-line-height: 2.75rem; // 44px
$h2-line-height: 2.25rem; // 36px
$h3-line-height: 2rem; // 32px
$h4-line-height: 2.5rem; // 28px
$h5-line-height: 1.75rem; // 24px
$h6-line-height: 1.25rem; // 20px

// Mobile
$h1-font-size-sm: $font-size-base * 2; // 32px
$h2-font-size-sm: $font-size-base * 1.75; // 28px
$h3-font-size-sm: $font-size-base * 1.5; // 24px
$h4-font-size-sm: $font-size-base * 1.5; // 24px
$h5-font-size-sm: $font-size-base * 1.25; // 20px
$h6-font-size-sm: $font-size-base * 1; // 16px

$h1-line-height-sm: 2rem; // 32px
$h2-line-height-sm: 1.75rem; // 28px
$h3-line-height-sm: 1.5rem; // 24px
$h4-line-height-sm: 1.5rem; // 24px
$h5-line-height-sm: 1.5rem; // 20px
$h6-line-height-sm: 1; // 16px

:root {
  --boels-h1-font-size: #{$h1-font-size};
  --boels-h2-font-size: #{$h2-font-size};
  --boels-h3-font-size: #{$h3-font-size};
  --boels-h4-font-size: #{$h4-font-size};
  --boels-h5-font-size: #{$h5-font-size};
  --boels-h6-font-size: #{$h6-font-size};
  --boels-h1-line-height: #{$h1-line-height};
  --boels-h2-line-height: #{$h2-line-height};
  --boels-h3-line-height: #{$h3-line-height};
  --boels-h4-line-height: #{$h4-line-height};
  --boels-h5-line-height: #{$h5-line-height};
  --boels-h6-line-height: #{$h6-line-height};

  --boels-h1-font-size-sm: #{$h1-font-size-sm};
  --boels-h2-font-size-sm: #{$h2-font-size-sm};
  --boels-h3-font-size-sm: #{$h3-font-size-sm};
  --boels-h4-font-size-sm: #{$h4-font-size-sm};
  --boels-h5-font-size-sm: #{$h5-font-size-sm};
  --boels-h6-font-size-sm: #{$h6-font-size-sm};
  --boels-h1-line-height-sm: #{$h1-line-height-sm};
  --boels-h2-line-height-sm: #{$h2-line-height-sm};
  --boels-h3-line-height-sm: #{$h3-line-height-sm};
  --boels-h4-line-height-sm: #{$h4-line-height-sm};
  --boels-h5-line-height-sm: #{$h5-line-height-sm};
  --boels-h6-line-height-sm: #{$h6-line-height-sm};

  @each $weight, $value in $font-weights {
    #{$boels-prefix}-font-weight-#{$weight}: #{$value};
  }

  @each $size, $value in $font-sizes {
    #{$boels-prefix}-font-size-#{$size}: #{$value};
  }
}

font-s h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $headings-font-family;
  font-weight: map-get($font-weights, bold);
  margin: 0;
  color: var(--bds-semantic-color-default-text);
}

h1,
.h1 {
  font-size: $h1-font-size-sm;
  line-height: $h1-line-height-sm;
  color: var(--bds-semantic-color-default-text);
  margin: 0;
  @include media.breakpoint('<=md') {
    font-size: 28px;
  }
}

h2,
.h2 {
  font-size: $h2-font-size-sm;
  line-height: $h2-line-height-sm;
}

h3,
.h3 {
  font-size: $h3-font-size-sm;
  line-height: $h3-line-height-sm;
}

h4,
.h4 {
  font-size: $h4-font-size-sm;
  line-height: $h4-line-height-sm;
}

h5,
.h5 {
  font-size: $h5-font-size-sm;
  line-height: $h5-line-height-sm;
}

h6,
.h6 {
  font-size: $h6-font-size-sm;
  line-height: $h6-line-height-sm;
}

p {
  font-size: $font-size-base;
  line-height: normal;
  margin: 0;

  &.text-lg {
    font-size: $font-size-lg;
  }

  &.text-sm {
    font-size: $font-size-sm;
  }

  a {
    font-weight: bold;
  }
}
