@mixin boels-tokens {
  --bds-brand-color-primary: #ff6600;
  --bds-brand-color-cta: #0065e0;
  --bds-brand-color-cta-tertiary: #e9dd03;
  --bds-brand-color-lightblack: #222222;
  --bds-brand-color-mediumgray: #aaaaaa;
  --bds-brand-color-lightgray: #cccccc;
  --bds-brand-color-lightergray: #eeeeee;
  --bds-brand-color-darkgray: #4e4e4e;
  --bds-brand-font-base: Barlow, -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto', 'Helvetica Neue', arial,
    sans-serif;
  --bds-core-color-black: #000000;
  --bds-core-color-white: #ffffff;
  --bds-core-color-darkwhite: #e8e8e8;
  --bds-core-color-explicit-disabled-text: #9b979e;
  --bds-core-color-explicit-disabled-container: #e2dce4;
  --bds-core-time-duration-short: 200;
  --bds-core-time-duration-base: 400;
  --bds-core-time-duration-long: 600;
  --bds-core-typography-size-small: 0.875rem;
  --bds-core-typography-size-small-line-height: 1.5rem;
  --bds-core-typography-size-regular: 1rem;
  --bds-core-typography-size-regular-line-height: 1.5rem;
  --bds-core-typography-size-large: 1.375rem;
  --bds-core-typography-size-large-line-height: 2rem;
  --bds-core-typography-size-extra-large: 1.625rem;
  --bds-core-typography-size-extra-large-line-height: 2rem;
  --bds-semantic-color-neutral-text: #5b5b5b;
  --bds-semantic-color-neutral-container: #dfdfdf;
  --bds-semantic-color-info-text: #0f6270;
  --bds-semantic-color-info-container: #bbdee3;
  --bds-semantic-color-warning-text: #6d5200;
  --bds-semantic-color-warning-container: #fcdd9c;
  --bds-semantic-color-success-text: #156800;
  --bds-semantic-color-success-container: #c0ebc5;
  --bds-semantic-color-danger-text: #c51f20;
  --bds-semantic-color-danger-container: #ffdbdf;
  --bds-semantic-color-input-selected-container: #199400;
  --bds-semantic-color-placeholder-text: #767676;
  --bds-semantic-dimension-border-radius-none: 0;
  --bds-semantic-dimension-border-radius-small: 0.25rem;
  --bds-semantic-dimension-border-radius-large: 1rem;
  --bds-semantic-dimension-row-padding-top: 0.75rem;
  --bds-semantic-dimension-row-padding-bottom: 0.75rem;
  --bds-semantic-dimension-row-min-height: 3rem;
  --bds-semantic-dimension-spacer-bff: 0.25rem;
  --bds-semantic-dimension-spacer-best-friends: 0.5rem;
  --bds-semantic-dimension-spacer-close-friends: 1rem;
  --bds-semantic-dimension-spacer-friends: 1.5rem;
  --bds-semantic-dimension-spacer-acquaintances: 2rem;
  --bds-semantic-dimension-spacer-familiars: 3rem;
  --bds-semantic-dimension-spacer-strangers: 4rem;
  --bds-semantic-dimension-spacer-bff-inverse: -0.25rem;
  --bds-semantic-dimension-spacer-best-friends-inverse: -0.5rem;
  --bds-semantic-dimension-spacer-close-friends-inverse: -1rem;
  --bds-semantic-dimension-spacer-friends-inverse: -1.5rem;
  --bds-semantic-dimension-spacer-acquaintances-inverse: -2rem;
  --bds-semantic-dimension-spacer-familiars-inverse: -3rem;
  --bds-semantic-dimension-spacer-strangers-inverse: -4rem;
  --bds-semantic-elevation-level1: 0 2px 2px rgba(0, 0, 0, 0.25);
  --bds-semantic-elevation-level2: 0 4px 4px rgba(0, 0, 0, 0.25);
  --bds-semantic-elevation-level3: 0 8px 8px rgba(0, 0, 0, 0.25);
  --bds-semantic-focus-outline: 2px solid #ffffff;
  --bds-semantic-focus-outline-offset-outset: 0.125rem;
  --bds-semantic-focus-outline-offset-inset: -0.125rem;
  --bds-semantic-focus-box-shadow: 0 0 0 2px #000000;
  --bds-semantic-focus-inversed-outline: 2px solid #000000;
  --bds-semantic-focus-inversed-box-shadow: 0 0 0 2px #ffffff;
  --bds-semantic-font-small: 0.875rem/1.5rem Barlow, -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', arial, sans-serif;
  --bds-semantic-font-base: 1rem/1.5rem Barlow, -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', arial, sans-serif;
  --bds-semantic-font-section-title: bold 1.375rem/2rem Barlow, -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', arial, sans-serif;
  --bds-semantic-font-page-title: bold 1.625rem/2rem Barlow, -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', arial, sans-serif;
  --bds-semantic-typography-size-root: 16px;
  --bds-semantic-typography-size-root-line-height: 1.5;
  --bds-component-button-color-ghost-container: rgba(0, 0, 0, 0);
  --bds-component-button-border-radius: 6rem;
  --bds-component-button-height: 2.5rem;
  --bds-component-card-heading-font: bold 1.375rem/2rem Barlow, -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', arial, sans-serif;
  --bds-component-card-heading-padding-block: 0.5rem;
  --bds-component-drawer-heading-font: bold 1.375rem/2rem Barlow, -apple-system, blinkmacsystemfont, 'Segoe UI',
    'Roboto', 'Helvetica Neue', arial, sans-serif;
  --bds-component-dropdown-heading-font: bold 1.375rem/2rem Barlow, -apple-system, blinkmacsystemfont, 'Segoe UI',
    'Roboto', 'Helvetica Neue', arial, sans-serif;
  --bds-component-dropdown-heading-padding-block: 0.5rem;
  --bds-component-pill-font-weight: regular;
  --bds-component-pill-border-radius: 6rem;
  --bds-component-table-thead-hover-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25));
  --bds-component-table-scroll-track-color: rgba(0, 0, 0, 0);
  --bds-platform-core-font-fallback: -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto', 'Helvetica Neue', arial,
    sans-serif;
}

@mixin boels-reference-tokens {
  --bds-core-font-family-headers: var(--bds-brand-font-base);
  --bds-core-font-family-base: var(--bds-brand-font-base);
  --bds-semantic-color-input-disabled-text: var(--bds-brand-color-lightgray);
  --bds-semantic-color-input-disabled-container: var(--bds-brand-color-lightgray);
  --bds-semantic-color-input-explicit-disabled-text: var(--bds-core-color-explicit-disabled-text);
  --bds-semantic-color-input-explicit-disabled-container: var(--bds-core-color-explicit-disabled-container);
  --bds-semantic-color-input-help-text: var(--bds-semantic-color-placeholder-text);
  --bds-semantic-color-input-hover-container: var(--bds-semantic-color-success-container);
  --bds-semantic-color-input-cta-text: var(--bds-core-color-white);
  --bds-semantic-color-input-cta-container: var(--bds-brand-color-cta);
  --bds-semantic-color-input-cta-text-hover: var(--bds-core-color-white);
  --bds-semantic-color-input-cta-container-hover: var(--bds-brand-color-lightblack);
  --bds-semantic-color-input-default-text: var(--bds-semantic-color-default-text);
  --bds-semantic-color-input-default-container: var(--bds-semantic-color-default-container);
  --bds-semantic-color-input-default-text-hover: var(--bds-core-color-white);
  --bds-semantic-color-input-default-container-hover: var(--bds-brand-color-lightblack);
  --bds-semantic-color-input-default-border: var(--bds-brand-color-lightblack);
  --bds-semantic-color-input-default-border-hover: var(--bds-semantic-color-input-default-border);
  --bds-semantic-color-input-promote-text: var(--bds-core-color-white);
  --bds-semantic-color-input-promote-container: var(--bds-brand-color-lightblack);
  --bds-semantic-color-input-promote-text-hover: var(--bds-core-color-white);
  --bds-semantic-color-input-promote-container-hover: var(--bds-brand-color-cta);
  --bds-semantic-color-default-text: var(--bds-brand-color-lightblack);
  --bds-semantic-color-default-container: var(--bds-core-color-white);
  --bds-semantic-color-secondary-text: var(--bds-core-color-white);
  --bds-semantic-color-secondary-container: var(--bds-brand-color-lightblack);
  --bds-semantic-color-primary-text: var(--bds-core-color-white);
  --bds-semantic-color-primary-container: var(--bds-brand-color-primary);
  --bds-semantic-color-layout-container: var(--bds-brand-color-lightergray);
  --bds-semantic-color-light-border: var(--bds-brand-color-lightgray);
  --bds-semantic-color-panel-border: var(--bds-brand-color-mediumgray);
  --bds-semantic-color-input-border: var(--bds-brand-color-mediumgray);
  --bds-semantic-color-inner-border: var(--bds-brand-color-lightgray);
  --bds-semantic-color-background-hover: var(--bds-brand-color-lightergray);
  --bds-semantic-color-background-inner-hover: var(--bds-brand-color-lightgray);
  --bds-semantic-dimension-padding-block-default: var(--bds-semantic-dimension-spacer-close-friends);
  --bds-semantic-dimension-padding-inline-default: var(--bds-semantic-dimension-spacer-friends);
  --bds-semantic-focus-border-radius: var(--bds-semantic-dimension-border-radius-small);
  --bds-semantic-typography-size-small: var(--bds-core-typography-size-small);
  --bds-semantic-typography-size-small-line-height: var(--bds-core-typography-size-small-line-height);
  --bds-semantic-typography-size-base: var(--bds-core-typography-size-regular);
  --bds-semantic-typography-size-base-line-height: var(--bds-core-typography-size-regular-line-height);
  --bds-semantic-typography-size-section-title: var(--bds-core-typography-size-large);
  --bds-semantic-typography-size-section-title-line-height: var(--bds-core-typography-size-large-line-height);
  --bds-semantic-typography-size-page-title: var(--bds-core-typography-size-extra-large);
  --bds-semantic-typography-size-page-title-line-height: var(--bds-core-typography-size-extra-large-line-height);
  --bds-component-button-color-cta-text: var(--bds-semantic-color-input-cta-text);
  --bds-component-button-color-cta-container: var(--bds-semantic-color-input-cta-container);
  --bds-component-button-color-cta-text-hover: var(--bds-semantic-color-input-cta-text-hover);
  --bds-component-button-color-cta-container-hover: var(--bds-semantic-color-input-cta-container-hover);
  --bds-component-button-color-default-text: var(--bds-semantic-color-input-default-text);
  --bds-component-button-color-default-container: var(--bds-semantic-color-input-default-container);
  --bds-component-button-color-default-text-hover: var(--bds-semantic-color-input-default-text-hover);
  --bds-component-button-color-default-container-hover: var(--bds-semantic-color-input-default-container-hover);
  --bds-component-button-color-default-border: var(--bds-semantic-color-input-default-border);
  --bds-component-button-color-default-border-hover: var(--bds-semantic-color-input-default-border);
  --bds-component-button-color-disabled-text: var(--bds-semantic-color-input-disabled-text);
  --bds-component-button-color-disabled-container: var(--bds-semantic-color-input-disabled-container);
  --bds-component-button-color-explicit-disabled-text: var(--bds-semantic-color-input-explicit-disabled-text);
  --bds-component-button-color-explicit-disabled-container: var(--bds-semantic-color-input-explicit-disabled-container);
  --bds-component-button-color-promote-text: var(--bds-semantic-color-input-promote-text);
  --bds-component-button-color-promote-container: var(--bds-semantic-color-input-promote-container);
  --bds-component-button-color-promote-text-hover: var(--bds-semantic-color-input-promote-text-hover);
  --bds-component-button-color-promote-container-hover: var(--bds-semantic-color-input-promote-container-hover);
  --bds-component-button-color-ghost-text: var(--bds-semantic-color-default-text);
  --bds-component-button-padding-inline: var(--bds-semantic-dimension-spacer-friends);
  --bds-component-button-padding-block: var(--bds-semantic-dimension-spacer-bff);
  --bds-component-card-media-min-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-card-heading-min-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-card-border-color: var(--bds-semantic-color-panel-border);
  --bds-component-card-border-radius: var(--bds-semantic-dimension-border-radius-small);
  --bds-component-card-background-color: var(--bds-semantic-color-default-container);
  --bds-component-card-color: var(--bds-semantic-color-default-text);
  --bds-component-card-padding-inline: var(--bds-semantic-dimension-padding-inline-default);
  --bds-component-card-padding-block: var(--bds-semantic-dimension-padding-block-default);
  --bds-component-card-footer-min-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-drawer-border-color: var(--bds-semantic-color-panel-border);
  --bds-component-drawer-background-color: var(--bds-semantic-color-default-container);
  --bds-component-drawer-color: var(--bds-semantic-color-default-text);
  --bds-component-drawer-padding-inline: var(--bds-semantic-dimension-padding-inline-default);
  --bds-component-drawer-padding-block: var(--bds-semantic-dimension-padding-block-default);
  --bds-component-drawer-elevation: var(--bds-semantic-elevation-level2);
  --bds-component-dropdown-elevation: var(--bds-semantic-elevation-level1);
  --bds-component-dropdown-heading-min-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-dropdown-border-color: var(--bds-semantic-color-panel-border);
  --bds-component-dropdown-border-radius: var(--bds-semantic-dimension-border-radius-small);
  --bds-component-dropdown-background-color: var(--bds-semantic-color-default-container);
  --bds-component-dropdown-color: var(--bds-semantic-color-default-text);
  --bds-component-dropdown-padding-inline: var(--bds-semantic-dimension-padding-inline-default);
  --bds-component-dropdown-padding-block: var(--bds-semantic-dimension-padding-block-default);
  --bds-component-dropdown-footer-min-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-input-checkbox-border-color: var(--bds-semantic-color-input-border);
  --bds-component-input-checkbox-border-radius: var(--bds-semantic-dimension-border-radius-small);
  --bds-component-input-checkbox-default-container: var(--bds-core-color-white);
  --bds-component-input-checkbox-selected-container: var(--bds-semantic-color-input-selected-container);
  --bds-component-input-checkbox-hover-container: var(--bds-semantic-color-input-hover-container);
  --bds-component-input-checkbox-help-text: var(--bds-semantic-color-input-help-text);
  --bds-component-input-checkbox-disabled-text: var(--bds-semantic-color-input-disabled-text);
  --bds-component-input-checkbox-disabled-container: var(--bds-semantic-color-input-disabled-container);
  --bds-component-input-text-background-color: var(--bds-semantic-color-default-container);
  --bds-component-input-text-border-color: var(--bds-semantic-color-input-border);
  --bds-component-input-text-border-radius: var(--bds-semantic-dimension-border-radius-small);
  --bds-component-input-text-padding-inline: var(--bds-semantic-dimension-spacer-close-friends);
  --bds-component-input-text-padding-block: var(--bds-semantic-dimension-spacer-bff);
  --bds-component-input-text-medium-padding-inline: var(--bds-semantic-dimension-spacer-best-friends);
  --bds-component-input-text-medium-padding-block: var(--bds-semantic-dimension-spacer-bff);
  --bds-component-input-text-item-gap: var(--bds-semantic-dimension-spacer-best-friends);
  --bds-component-input-text-line-height: var(--bds-semantic-typography-size-base-line-height);
  --bds-component-input-text-min-height: var(--bds-core-typography-size-large-line-height);
  --bds-component-input-text-medium-height: var(--bds-core-typography-size-large-line-height);
  --bds-component-input-text-large-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-list-item-background-color-hover: var(--bds-semantic-color-background-hover);
  --bds-component-list-item-border-color: var(--bds-semantic-color-inner-border);
  --bds-component-list-item-padding-top: var(--bds-semantic-dimension-row-padding-top);
  --bds-component-list-item-padding-bottom: var(--bds-semantic-dimension-row-padding-bottom);
  --bds-component-list-item-min-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-pill-font: var(--bds-semantic-font-small);
  --bds-component-pill-padding-inline: var(--bds-semantic-dimension-spacer-close-friends);
  --bds-component-pill-padding-block: var(--bds-semantic-dimension-spacer-bff);
  --bds-component-site-header-top-color: var(--bds-semantic-color-secondary-text);
  --bds-component-site-header-top-container: var(--bds-semantic-color-secondary-container);
  --bds-component-site-header-middle-color: var(--bds-semantic-color-primary-text);
  --bds-component-site-header-middle-container: var(--bds-semantic-color-primary-container);
  --bds-component-site-header-bottom-color: var(--bds-semantic-color-default-text);
  --bds-component-site-header-bottom-container: var(--bds-semantic-color-default-container);
  --bds-component-skeleton-min-height: var(--bds-core-typography-size-small-line-height);
  --bds-component-table-border-color: var(--bds-semantic-color-panel-border);
  --bds-component-table-border-radius: var(--bds-semantic-dimension-border-radius-small);
  --bds-component-table-cell-padding-inline: var(--bds-semantic-dimension-spacer-close-friends);
  --bds-component-table-thead-min-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-table-thead-border-color: var(--bds-semantic-color-inner-border);
  --bds-component-table-thead-background-color: var(--bds-semantic-color-default-container);
  --bds-component-table-thead-default-text: var(--bds-semantic-color-default-text);
  --bds-component-table-tbody-min-height: var(--bds-semantic-dimension-row-min-height);
  --bds-component-table-tbody-border-color: var(--bds-semantic-color-inner-border);
  --bds-component-table-tbody-background-color: var(--bds-semantic-color-default-container);
  --bds-component-table-tbody-default-text: var(--bds-semantic-color-default-text);
  --bds-component-table-scroll-thumb-color: var(--bds-brand-color-mediumgray);
  --bds-component-table-background-hover: var(--bds-semantic-color-background-hover);
}
