@import './themes/palette.scss';

$white: #fff;
$gray: #7f7f7f;
$gray-100: #f1f1f1;
$gray-200: #f2f3f4;
$gray-300: #d8dcde;
$gray-400: #b8b8b8;
$gray-500: #a4a4a4;
$gray-600: #87979e;
$gray-700: #7f7f7f;
$gray-800: #3e4e58;
$gray-900: #222;
$black: #000;

$grays: ();
$grays: map-merge(
  (
    'gray': $gray,
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
  ),
  $grays
);

$primary: map-get($boels-primary-color, 500);
$primary-dark: map-get($boels-primary-color, 700);
$primary-text-light: map-get($boels-primary-color, 150);
$secondary: $gray-800;
$text: $gray-900;
$text-background: $white;
$text-border: $gray-400;
$success: var(--bds-semantic-color-success-text);
$success-background: var(--bds-semantic-color-success-container);
$success-border: #beedc9;
$error: var(--bds-semantic-color-danger-text);
$error-background: var(--bds-semantic-color-danger-container);
$error-border: #fcc1c2;
$danger: var(--bds-semantic-color-danger-text);
$danger-background: var(--bds-semantic-color-danger-container);
$danger-border: #fbacad;
$warning: var(--bds-semantic-color-warning-text);
$warning-background: var(--bds-semantic-color-warning-container);
$warning-border: #ffe1a5;
$info: var(--bds-semantic-color-info-text);
$info-background: var(--bds-semantic-color-info-container);
$info-border: #95c9ff;
$neutral: var(--bds-semantic-color-neutral-text);
$neutral-background: var(--bds-semantic-color-neutral-container);
$neutral-border: #e0e0e0;
$light: #d3d6db;
$dark: #212738;
$background: #f4f4f4;
$inverse: #fff;
$transparent: transparent;
$visual-focus: map-get($boels-primary-color, 500);
$dropdown-hover: #e5f5fb;

$theme-colors: (
  'primary': $primary,
  'primary-dark': $primary-dark,
  'primary-text-light': $primary-text-light,
  'secondary': $secondary,
  'black': $black,
  'white': $white,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'background': $background,
  'inverse': $inverse,
  'text': $text,
  'transparent': $transparent,
  'visual-focus': $visual-focus,
  'dropdown-hover': $dropdown-hover,
);

$status-colors: (
  'neutral': (
    'background': $neutral-background,
    'border': $neutral-border,
    'color': $neutral,
  ),
  'info': (
    'background': $info-background,
    'border': $info-border,
    'color': $info,
  ),
  'success': (
    'background': $success-background,
    'border': $success-border,
    'color': $success,
  ),
  'warning': (
    'background': $warning-background,
    'border': $warning-border,
    'color': $warning,
  ),
  'danger': (
    'background': $danger-background,
    'border': $danger-border,
    'color': $danger,
  ),
  'error': (
    'background': $error-background,
    'border': $error-border,
    'color': $error,
  ),
);

$ui-colors: map-merge(
  $status-colors,
  (
    'text': (
      'background': $text-background,
      'border': $text-border,
      'color': $text,
    ),
  )
);

@mixin app-colors {
  @each $color, $value in $theme-colors {
    --boels-color-#{$color}: #{$value};
  }

  @each $color-group, $variants in $ui-colors {
    @each $variant, $value in $variants {
      // --boels-color-success: ..
      // --boels-color-success-background: ..
      // --boels-color-success-border: ..
      $color-name: if($variant == 'color', $color-group, $color-group + '-' + $variant);
      --boels-color-#{$color-name}: #{$value};
    }
  }

  @each $gray, $value in $grays {
    --boels-color-#{$gray}: #{$value};
  }

  --boels-background-color: #{$secondary};
}

// Allows to override existing base values with theme vars
:root,
.boels,
.cramo {
  @include app-colors();
}
