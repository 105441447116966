@use 'styles/include-media' as media;

@mixin visible-focus {
  outline-style: solid;
  outline-color: var(--boels-color-visual-focus);
  outline-width: 2px;
  outline-offset: 4px;
  transition: none;
}

@mixin side-paddings {
  padding-left: var(--boels-space-8);
  padding-right: var(--boels-space-8);

  @include media.breakpoint('>=sm') {
    padding-left: var(--boels-space-16);
    padding-right: var(--boels-space-16);
  }

  @include media.breakpoint('>=md') {
    padding-left: var(--boels-space-16);
    padding-right: var(--boels-space-16);
  }

  @include media.breakpoint('>=lg') {
    padding-left: var(--boels-space-48);
    padding-right: var(--boels-space-48);
  }

  @include media.breakpoint('>=xl') {
    padding-left: var(--boels-space-84);
    padding-right: var(--boels-space-84);
  }
}

@mixin line-clamp($size) {
  line-clamp: $size;
  -webkit-line-clamp: $size;
}

@mixin tabs-paddings {
  padding-top: 36px;
}
