@use 'styles/include-media' as media;
@import 'styles/typography';

:root {
  --chat-icon-size: 25px;
}

.embeddedServiceLiveAgentStateChatItem.chatMessage {
  background-color: var(--boels-color-gray-200) !important;
  padding: 0 16px !important;
}

.embeddedServiceLiveAgentStateChatItem.agent .agentName {
  margin-left: 4px !important;
}

.embeddedServiceLiveAgentStateChatItem .isLightningOutContext .avatar {
  display: none;
}

.embeddedServiceLiveAgentStateChatAction.uiButton:disabled svg,
.embeddedServiceLiveAgentStateChatAction.uiButton:disabled:hover svg,
.embeddedServiceLiveAgentStateChatAction.uiButton:disabled:focus svg {
  fill: var(--boels-color-black) !important;
}

.embeddedServiceLiveAgentStateChatBubble.chasitor .chatContent {
  background: var(--boels-color-white) !important;
  color: var(--bds-semantic-color-default-text) !important;
}

.embeddedServiceLiveAgentStateChatBubble .chatContent {
  margin-left: 0 !important;
}

.embeddedServiceSidebarButton {
  height: 48px !important;
}

.embeddedServiceSidebarButton:focus {
  box-shadow: none !important;
}

.embeddedServiceSidebarButton:not(:disabled):focus,
.embeddedServiceSidebarButton:not(:disabled):hover {
  background: var(--boels-color-primary) !important;
}

.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover {
  background: var(--boels-color-gray-200) !important;
}

.embeddedServiceSidebarButton .label,
.embeddedServiceSidebarButton:disabled .label {
  font-weight: bold !important;
}

.embeddedServiceSidebarFormField.inputSplitName {
  width: calc(100% - 12px) !important;
}

.embeddedServiceSidebarFormField .split-field-container,
.embeddedServiceSidebarFormField .uiInput--input,
.embeddedServiceSidebarFormField .uiInput--select {
  margin-bottom: 12px !important;
}

.embeddedServiceSidebarFormField .slds-style-inputtext,
.embeddedServiceSidebarFormField .slds-style-select {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 6px !important;
  height: 40px !important;
}

.embeddedServiceSidebarFormField .slds-style-inputtext:focus,
.embeddedServiceSidebarFormField .slds-style-select:focus {
  border: 1px solid var(--bds-component-input-text-border-color) !important;
  box-shadow: none !important;
}

.embeddedServiceFlowInputDateTime .form-element__control,
.embeddedServiceFlowInputDateTime .input-has-icon {
  height: 48px !important;
}

.embeddedServiceLiveAgentQueuePosition .queuePositionMessage {
  margin: 2px 0 70px !important;

  p {
    margin: 0 !important;
  }
}

.embeddedServiceLiveAgentQueuePosition.queuePositionWaiting .youAreNextMessage {
  margin: 17px 0 2px !important;
  line-height: 1.2 !important;
}

.embeddedServiceLiveAgentQueuePosition .queuePositionChatIcon {
  background-color: rgb(62, 78, 88);
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.embeddedServiceLiveAgentQueuePosition .embeddedServiceIcon > svg {
  display: none;
}

.embeddedServiceLiveAgentQueuePosition .embeddedServiceLoadingBalls .loadingBall {
  background-color: rgba(255, 255, 255, 0.84) !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.plaintextContent {
  border-radius: 6px 6px 0 !important;
  margin-left: 0 !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.agent.plaintextContent {
  color: var(--boels-color-black) !important;
  background: var(--boels-color-gray-300) !important;
  border-radius: 6px 6px 6px 0 !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
  background: var(--boels-color-white) !important;
  color: var(--bds-semantic-color-default-text) !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent a {
  color: var(--bds-semantic-color-default-text) !important;
}

.embeddedServiceSidebarForm.formContent {
  height: calc(100% - 73px) !important;
  margin-bottom: 73px !important;
}

.embeddedServiceSidebarForm.buttonWrapper {
  padding: 16px 16px 9px !important;
}

.embeddedServiceSidebarForm .fieldList {
  margin: 11px 16px 0 4px !important;
}

.embeddedServiceLiveAgentStateChat .messageArea {
  background-color: var(--boels-color-gray-200) !important;
}

.embeddedServiceLiveAgentStateChat .messageArea:focus {
  border: none !important;
}

.embeddedServiceLiveAgentStateChat .chatSessionStartTime {
  flex: 0 1 auto !important;
  flex-direction: column !important;
}

.embeddedServiceLiveAgentStateChat .endChatContainer .endChatButton {
  height: 48px !important;
  margin: 0 24px 8px !important;
}

.embeddedServiceLiveAgentStateWaiting .waitingGreetingContent {
  margin: 12px 0 5px !important;
}

.embeddedServiceLiveAgentStateWaiting .embeddedServiceLiveAgentQueuePosition {
  margin: 0 0 12px !important;
  align-items: center;
}

.embeddedServiceSidebar.modalContainer {
  font-family: $font-family-base !important;
}

.embeddedServiceSidebar.layout-docked .dockableContainer,
.embeddedServiceSidebar.layout-float .dockableContainer {
  box-shadow: 0px 4px 8px rgb(34 34 34 / 24%) !important;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 10px !important;
}

.embeddedServiceSidebar.layout-docked .dockableContainer {
  border-radius: 10px 10px 0 0 !important;
}

.embeddedServiceSidebar.layout-float .dockableContainer {
  border-radius: 10px 10px 10px 10px !important;
}

.embeddedServiceSidebarMinimizedDefaultUI.sidebarHeader {
  height: 46px !important;
  min-width: 10em !important;
  max-width: 14em !important;
  width: 168px !important;
  padding: 8px 12px !important;
}

.embeddedServiceSidebarMinimizedDefaultUI .content {
  width: 100%;
  padding: 0 !important;
  flex-shrink: 0;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedText {
  width: 100%;
  margin: 0 8px;
}

.embeddedServiceSidebarMinimizedDefaultUI:not(.helpButton) .messageContent {
  flex-direction: row !important;
}

.embeddedServiceSidebarMinimizedDefaultUI:not(.helpButton) .minimizedText {
  text-align: left !important;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedImage {
  display: none !important;
}
.embeddedServiceSidebarMinimizedDefaultUI .minimizedImage,
.embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
  margin-right: 0 !important;
}

img[embeddedService-chatHeader_chatHeader] {
  display: none;
}

[embeddedservice-chatheader_chatheader-host] {
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  background-color: rgb(34, 34, 34) !important;
}

h2[embeddedService-chatHeader_chatHeader] {
  color: var(--boels-color-white);
  font-weight: var(--boels-font-weight-bold) !important;
}

.embeddedServiceHelpButton {
  letter-spacing: -0.1px !important;
  font-family: $font-family-base !important;
  font-size: $font-size-base !important;
  line-height: var(--bds-semantic-typography-size-root-line-height) !important;

  .helpButton {
    right: 21px !important;
    bottom: 21px !important;

    @include media.breakpoint('<sm') {
      right: 16px !important;
      bottom: 68px !important;
    }

    .uiButton {
      border-radius: 10px 10px 0px 10px !important;
      box-shadow: 0px 2px 4px rgb(34 34 34 / 48%) !important;
      background-color: var(--boels-color-black) !important;
      padding: 8px 12px !important;
      &:before {
        display: none;
      }

      &.helpButtonDisabled {
        display: none;
      }

      .helpButtonLabel {
        margin: 0 8px !important;
      }
    }

    .embeddedServiceIcon {
      display: block !important;
      margin-right: 0 !important;

      &:before {
        font-size: 0;
        background: url('/assets/images/icon-chat.svg') no-repeat center center / 100%;
        width: var(--chat-icon-size);
        height: var(--chat-icon-size);
        display: block;
      }
    }
  }
}

.embeddedServiceSidebarMinimizedDefaultUI {
  border-radius: 10px 10px 0px 10px !important;
  box-shadow: 0px 2px 4px rgb(34 34 34 / 48%) !important;
  background-color: var(--boels-color-black) !important;
  right: 21px !important;
  bottom: 21px !important;
  border: none;

  @include media.breakpoint('<sm') {
    bottom: 68px !important;
    right: 16px !important;
  }

  &.helpButton {
    bottom: 68px !important;
    right: 16px !important;
    border: none;

    @include media.breakpoint('>=sm') {
      right: 21px !important;
      bottom: 21px !important;
      flex-direction: column !important;
    }
  }

  .embeddedServiceIcon {
    &:after {
      content: '';
      background: url('/assets/images/icon-chat.svg') no-repeat center center / 100%;
      width: var(--chat-icon-size);
      height: var(--chat-icon-size);
      display: block;
    }

    & svg {
      display: none;
    }
  }
}

.sidebarHeader {
  button {
    @include media.breakpoint('<sm') {
      font-size: 6px;
      line-height: 1.2;
    }
  }
}
