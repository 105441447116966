$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    4: (
      $spacer * 0.25,
    ),
    6: (
      $spacer * 0.375,
    ),
    8: (
      $spacer * 0.5,
    ),
    10: (
      $spacer * 0.625,
    ),
    12: (
      $spacer * 0.75,
    ),
    14: (
      $spacer * 0.875,
    ),
    16: (
      $spacer,
    ),
    18: (
      $spacer * 1.125,
    ),
    20: (
      $spacer * 1.25,
    ),
    22: (
      $spacer * 1.375,
    ),
    24: (
      $spacer * 1.5,
    ),
    26: (
      $spacer * 1.6,
    ),
    28: (
      $spacer * 1.75,
    ),
    30: (
      $spacer * 1.875,
    ),
    32: (
      $spacer * 2,
    ),
    36: (
      $spacer * 2.25,
    ),
    40: (
      $spacer * 2.5,
    ),
    44: (
      $spacer * 2.75,
    ),
    48: (
      $spacer * 3,
    ),
    50: (
      $spacer * 3.125,
    ),
    52: (
      $spacer * 3.25,
    ),
    56: (
      $spacer * 3.5,
    ),
    60: (
      $spacer * 3.75,
    ),
    64: (
      $spacer * 4,
    ),
    72: (
      $spacer * 4.5,
    ),
    80: (
      $spacer * 5,
    ),
    84: (
      $spacer * 5.25,
    ),
    96: (
      $spacer * 6,
    ),
    100: (
      $spacer * 6.25,
    ),
    120: (
      $spacer * 7.5,
    ),
  ),
  $spacers
);

:root {
  @each $space, $value in $spacers {
    --boels-space-#{$space}: #{$value};
  }
}

// Usage:
// padding: var(--boels-space-20);
