@mixin truncate($truncation-boundary: false) {
  @if max-width {
    max-width: $truncation-boundary;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: inherit;
  background: inherit;

  &:hover {
    &:before {
      content: attr(title);
      position: absolute;
      z-index: z('content');
      background: inherit;
      color: inherit;
    }
  }
}
